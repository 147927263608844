import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { User } from '../../lib/store/user';
import NProgress from 'nprogress';
import notify from 'lib/notify';

type Props = {
  user: User;
  titleOverride?: string;
  title?: boolean;
  suppressCard?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const onSubmitNameUpdate = async (
  event: React.FormEvent<HTMLFormElement>,
  newFirstName: string,
  newLastName: string,
  setDisabled: Function,
  user: User,
  onSuccess: () => void,
) => {
  event.preventDefault();

  console.log(`${newFirstName} ${newLastName}`);

  if (!newFirstName || !newLastName) {
    notify('Name is required');
    return;
  }

  NProgress.start();
  setDisabled(true);

  try {
    await user.updateName({ firstName: newFirstName, lastName: newLastName });

    notify('You successfully updated your profile.');
    onSuccess && onSuccess();
  } catch (error) {
    notify(error);
  } finally {
    setDisabled(false);
    NProgress.done();
  }
};

const NameChunk = (props: Props) => {
  const { user, titleOverride, title, onSuccess, onCancel } = props;
  const { firstName, lastName } = user;
  const [disabled, setDisabled] = useState(false);
  const [newFirstName, setFirstName] = useState(firstName || '');
  const [newLastName, setLastName] = useState(lastName || '');

  return (
    <>
      {title && <CardHeader title={titleOverride ? titleOverride : 'Enter your name'}></CardHeader>}
      <CardContent>
        <form
          onSubmit={(event) =>
            onSubmitNameUpdate(event, newFirstName, newLastName, setDisabled, user, onSuccess)
          }
          autoComplete="off"
        >
          <TextField
            autoComplete="off"
            value={newFirstName}
            helperText="first name"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            fullWidth={true}
          />
          <br />
          <TextField
            autoComplete="off"
            value={newLastName}
            helperText="last name"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            fullWidth={true}
          />
          <div style={{ marginTop: '15px', display: 'flex' }}>
            {onCancel && (
              <Button
                variant="outlined"
                color="primary"
                onClick={onCancel}
                style={{ marginRight: 'auto' }}
              >
                Cancel
              </Button>
            )}
            <Button variant="outlined" color="primary" type="submit" disabled={disabled}>
              {firstName || lastName ? 'Update' : 'Save'}
            </Button>
          </div>
        </form>
      </CardContent>
    </>
  );
};

const EnterNameCard = (props: Props) => {
  const { suppressCard } = props;

  if (suppressCard) {
    return <span>{NameChunk(props)}</span>;
  }
  return <Card>{NameChunk(props)}</Card>;
};

export default EnterNameCard;
