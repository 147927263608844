import { Button, TextField } from '@material-ui/core';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CSSProperties, useEffect, useState } from 'react';
import { updatePaymentMethod } from '../../lib/api/payments';
import notify from '../../lib/notify';
import CardSection from '../payment/AddCardSection';

type Props = {
  email: string;
  onSuccess?: Function;
};

const styles: Record<string, CSSProperties> = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0px 10px 10px',
    borderRadius: '0.25rem',
  },
};

const StripeForm = (props: Props) => {
  const [stripeLoaded, setStripeLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [nameOnCard, setNameOnCard] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();
  const { onSuccess } = props;

  useEffect(() => {
    if (stripe) setStripeLoaded(true);
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        email: props.email,
        name: nameOnCard,
      },
    });

    if (error) {
      setError(error.message);
    } else {
      // You have successfully created a new PaymentMethod
      try {
        await updatePaymentMethod(paymentMethod);
        notify('payment method saved');
        // Hack for now to refresh the window. It should be a better event propogation to the parent component
        if (onSuccess) {
          onSuccess();
        } else {
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    }
    setLoading(false);
  };

  return (
    <form style={styles.form}>
      <div style={{ padding: '20px 20px 0px', width: '100%' }}>
        <TextField
          fullWidth
          value={nameOnCard}
          placeholder={'Name on Card'}
          onChange={(e) => setNameOnCard(e.target.value)}
        />
        <CardSection />
        {error && <div style={{ color: ' #cb4335', marginTop: '5px' }}>{error}</div>}
        <Button
          style={{ marginTop: '20px' }}
          color={'primary'}
          variant={'outlined'}
          disabled={!stripeLoaded || loading}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </form>
  );
};

export default StripeForm;
