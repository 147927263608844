import { Button } from '@material-ui/core';
import { useState } from 'react';
import { authorizePaymentForMeeting } from '../../lib/api/meeting';
import notify from '../../lib/notify';
import ClassConfirmedDialog from './ClassConfirmedDialog';

export // eslint-disable-next-line react/display-name
const AuthorizePaymentButton = ({ meeting, isAlreadyMember, style = {}, isMobile }) => {
  const [authorizingPaymentLoading, setAuthorizingPaymentLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const authorizePayment = async (setAuthorizingPaymentLoading, meeting, isAlreadyMember) => {
    const yes = confirm(
      `do you agree to the charge of $${meeting.rate + meeting.fee} for the class?`,
    );
    if (yes) {
      try {
        // Call the API to authorize the payment
        setAuthorizingPaymentLoading(true);
        await authorizePaymentForMeeting(meeting._id, !isAlreadyMember);
        setAuthorizingPaymentLoading(false);
        notify('Successfully authorized the payment.');
        setShowConfirmation(true);
      } catch (e) {
        console.error(e);
        notify('Error occurred while authorizing the payment.');
      }
    }
  };

  if (authorizingPaymentLoading) {
    return <span>Authorizing...</span>;
  } else {
    return showConfirmation ? (
      <ClassConfirmedDialog meeting={meeting} isOpen={showConfirmation} />
    ) : (
      <Button
        color={'primary'}
        variant={'contained'}
        style={style}
        onClick={() => authorizePayment(setAuthorizingPaymentLoading, meeting, isAlreadyMember)}
        fullWidth={isMobile}
      >
        Confirm place
      </Button>
    );
  }
};

export default AuthorizePaymentButton;
