import React, { CSSProperties, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from '../payment/StripeForm';
import SavedCard from '../payment/SavedCard';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Card, CardHeader } from '@material-ui/core';
import { Store } from 'lib/store';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLEKEY);

type Props = {
  userEmail: string;
  userHasCardInfo: boolean;
  userCard: {
    brand: string;
    funding: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
  open: boolean;
  onSuccess?: Function;
  store: Store;
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.25rem',
    marginBottom: '20px',
  },
};

const AttendeeCardInformation = (props: Props) => {
  const { userCard, userEmail, userHasCardInfo, open, onSuccess, store } = props;
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Card>
      {isOpen && !userHasCardInfo && <CardHeader title="Enter card information"></CardHeader>}
      {isOpen && userHasCardInfo && <CardHeader title="Card Information" />}
      {isOpen && (
        <div style={styles.container}>
          {userHasCardInfo ? (
            userCard && <SavedCard card={userCard} />
          ) : (
            <Elements stripe={stripePromise}>
              <StripeForm email={userEmail} onSuccess={onSuccess} />
            </Elements>
          )}
        </div>
      )}
      {!isOpen && (
        <Button
          variant="outlined"
          onClick={() => setIsOpen(true)}
          style={{ margin: '5px' }}
          fullWidth={store.isMobile}
        >
          Enter credit card
        </Button>
      )}
    </Card>
  );
};

export default AttendeeCardInformation;
