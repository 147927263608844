import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Meeting } from 'lib/store/meeting';
import Router from 'next/router';

interface Props {
  meeting: Meeting;
  isOpen: boolean;
}

function ClassConfirmedDialog(props: Props) {
  const { meeting, isOpen } = props;
  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth={true}>
      <DialogTitle id="form-dialog-title">You are confirmed</DialogTitle>
      <DialogContent>
        <img
          style={{ maxWidth: '100%' }}
          src="https://images.unsplash.com/photo-1495979905739-16e1a7d173b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80"
        />
        You are confirmed for your class.There are several ways to join a class once it has started:
        <ul>
          <li>
            You can open the email you receive when the teacher starts the class.It will take you to
            the class details where there will be a join button
          </li>
          <li>
            You can return to the site and join the class from the main menu (the join button only
            becomes active when the class is started)
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => Router.push(`/meetings/${meeting._id}/`)} color={'primary'}>
          View Class
        </Button>
        <Button onClick={() => window.location.reload()} color={'primary'}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClassConfirmedDialog;
