import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import EnterNameCard from 'components/dashboard/EnterNameCard';
import { requestJoinMeeting } from 'lib/api/meeting';
import { Store } from 'lib/store';
import { Meeting } from 'lib/store/meeting';
import Router from 'next/router';
import { useState } from 'react';
import { MEETING_STATE, PaymentType } from 'utils/constants';
import AuthorizePaymentButton from './AuthorizePaymentButton';
import ConfirmPlaceButton from './ConfirmPlaceButton';

type Props = {
  disabled: boolean;
  meeting: Meeting;
  store: Store;
  buttonMsg: string;
};

function getHasPaidFromMeeting(meeting, userId) {
  const entry = meeting.members.find((e) => e.userId == userId);
  return meeting.createdBy == userId || entry.hasAuthorizedPayment;
}

const doJoinMeeting = async (props: Props, setOpen) => {
  const { meeting, store } = props;
  const firstName = store.currentUser.firstName;
  const lastName = store.currentUser.lastName;
  if (firstName && lastName) {
    const userId = store.currentUser._id;
    const creatorId = meeting.createdBy;
    if (userId == creatorId) {
      const { invoice } = meeting;
      const isInvoice = meeting.paymentType == PaymentType.Invoice;

      if (isInvoice && (!invoice || invoice.status != 'paid' || !invoice.paid)) {
        const confirmation = confirm(
          'the invoice will be sent when this class is finished. Please make sure the billing information is correct before starting the class.',
        );
        if (!confirmation) {
          return;
        }
      }
    }
    const joinUrl = await requestJoinMeeting(meeting._id);
    window.open(joinUrl);
    setTimeout(() => {
      //delay push as a lame attempt to get the meeting after it has started
      Router.push(`/meetings/${meeting._id}`);
    }, 3000);
  } else {
    setOpen(true);
  }
};

const JoinMeetingTableAction = (props: Props) => {
  const { disabled, meeting, store, buttonMsg } = props;
  const hasCardInformation = store.currentUser.hasCardInformation;
  const [modalOpen, setOpen] = useState(false);

  const shouldFullwidth =
    meeting.state == MEETING_STATE.STARTED ||
    meeting.state == MEETING_STATE.CREATED ||
    meeting.state == MEETING_STATE.FINISHED;

  return (
    <>
      <Dialog open={modalOpen}>
        <DialogTitle>Please enter your name to join the class</DialogTitle>
        <DialogContent>
          <EnterNameCard
            user={store.currentUser}
            suppressCard={true}
            onSuccess={() => {
              setOpen(false);
              doJoinMeeting(props, setOpen);
            }}
            onCancel={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      {getHasPaidFromMeeting(meeting, store.currentUser._id) ? (
        <Button
          disabled={disabled}
          color={'primary'}
          variant={'contained'}
          fullWidth={shouldFullwidth}
          style={{ margin: 'auto' }}
          onClick={() => {
            doJoinMeeting(props, setOpen);
          }}
        >
          {buttonMsg}
        </Button>
      ) : meeting.paymentType == PaymentType.Free || meeting.paymentType == PaymentType.Invoice ? (
        <ConfirmPlaceButton
          meeting={meeting}
          style={{ margin: 'auto' }}
          isAlreadyMember={true}
          isMobile={store.isMobile}
        />
      ) : hasCardInformation ? (
        <AuthorizePaymentButton
          meeting={meeting}
          style={{ margin: 'auto' }}
          isAlreadyMember={true}
          isMobile={store.isMobile}
        />
      ) : (
        <Typography variant="h5" style={{ margin: 'auto' }}>
          View Details
        </Typography>
      )}
    </>
  );
};

export default JoinMeetingTableAction;
