import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import './index.module.scss';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#cb4335',
      iconColor: '#cb4335',
    },
  },
};

const CardSection = () => {
  return (
    <div >
      <div id={'card-number-element'}>
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id={'card-cvv-element'}>
          <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div id={'card-expiry-element'}>
          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div>
    </div>
  );
};

export default CardSection;
