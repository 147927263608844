import { Button } from '@material-ui/core';
import { useState } from 'react';
import { confirmPlaceForMeeting } from '../../lib/api/meeting';
import notify from '../../lib/notify';
import ClassConfirmedDialog from './ClassConfirmedDialog';

// eslint-disable-next-line react/display-name
const ConfirmPlaceButton = ({ meeting, style, isAlreadyMember, isMobile }) => {
  const [confirmingPaymentLoading, setConfirmingPaymentLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const confirmPlace = async (meeting, isAlreadyMember) => {
    try {
      // Call the API to authorize the payment
      setConfirmingPaymentLoading(true);
      const result = await confirmPlaceForMeeting(meeting._id, !isAlreadyMember);
      if (result) {
        setConfirmingPaymentLoading(false);
        setShowConfirmation(true);
        notify('Successfully confirmed your spot.');
      } else {
        throw 'did not confirm place';
      }
    } catch (e) {
      console.log(e);
      notify('Error occurred while confirming your place.');
    }
  };

  if (confirmingPaymentLoading) {
    return <span>Confirming...</span>;
  } else {
    return showConfirmation ? (
      <ClassConfirmedDialog meeting={meeting} isOpen={showConfirmation} />
    ) : (
      <Button
        color={'primary'}
        variant={'contained'}
        onClick={() => confirmPlace(meeting, isAlreadyMember)}
        style={style}
        fullWidth={isMobile}
      >
        Save my spot
      </Button>
      //TODO add a decline button, if its an invited member - not for public classes
    );
  }
};

export default ConfirmPlaceButton;
