import React, { CSSProperties } from 'react';

type Props = {
  card: {
    last4: string;
    brand: string;
    exp_month: number;
    exp_year: number;
  };
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '16px',
    padding: '10px 20px 10px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: 'auto',
  },
  card_info: {
    color: '#000',
    fontWeight: 'bold',
  },
};

const SavedCard = (props: Props) => {
  const {
    card: { last4, brand, exp_month, exp_year },
  } = props;

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <span style={styles.label}>Card Type</span>
        <span>{brand}</span>
      </div>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <span style={styles.label}>Card Number</span>
        <span>**** **** **** {last4}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <span style={styles.label}>Card Expiry</span>
        <span style={styles.card_info}>
          {exp_month}/{exp_year}
        </span>
      </div>
    </div>
  );
};

export default SavedCard;
